import React from "react";
import {
  CardContainer,
  ImgWrap,
  Img,
  StatsContainer,
  ProgressContainer,
  InfoWrap,
  TextWrapper,
  RowOne,
  RowTwo,
  StatsTitle,
  Stat,
  StatYield,
  Progress,
} from "./Elements";
import ImageThree from "../../../assets/Image_3.jpg";

const PropertyCard = () => {
  return (
    <>
      <CardContainer>
        <ImgWrap>
          <Img src={ImageThree} />
        </ImgWrap>
        <InfoWrap>
          <StatsContainer>
            <RowOne>
              <TextWrapper>
                <StatsTitle>Targetted IRR</StatsTitle>
                <Stat>21%</Stat>
              </TextWrapper>
              <TextWrapper>
                <StatsTitle>Net Cash Field</StatsTitle>
                <StatYield>1%</StatYield>
              </TextWrapper>
            </RowOne>
            <RowOne>
              <TextWrapper>
                <StatsTitle>Min Investment</StatsTitle>
                <Stat>INR 2.5M</Stat>
              </TextWrapper>
              <TextWrapper>
                <StatsTitle>Price /sqft</StatsTitle>
                <StatYield>INR 9.8K</StatYield>
              </TextWrapper>
            </RowOne>
            <RowTwo>
              <TextWrapper>
                <StatsTitle>Targetted Equity</StatsTitle>
                <Stat>3X</Stat>
              </TextWrapper>
              <TextWrapper>
                <StatsTitle>Hold Period</StatsTitle>
                <Stat>3-5 Years</Stat>
              </TextWrapper>
            </RowTwo>
          </StatsContainer>
          <ProgressContainer>
            <Progress>100% Funded</Progress>
          </ProgressContainer>
        </InfoWrap>
      </CardContainer>
    </>
  );
};

export default PropertyCard;
