import styled from "styled-components";
import { css } from "styled-components/macro";
import { IoMdArrowRoundForward } from "react-icons/io";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: #0f1723;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const HeroSectionContainer = styled.div`
  height: 100vh;
  max-height: 1100px;
  position: relative;

  overflow: hidden;
 
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const HeroSlide = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const HeroSlider = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  background: #101824;
  justify-content: center;
`;
export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  object-fit: cover;
`;

export const HeroSub = styled.span`
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  margin: 10px;
  text-align: left;
`;
export const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100%-100px);
  color: #fff;
  margin: 50px;

  h1 {
    font-size: clamp(1rem, 8vw, 2rem);
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: left;
    margin-bottom: 0.8rem;
  }

  p {
    margin-bottom: 1.2rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
`;
export const Arrow = styled(IoMdArrowRoundForward)``;

export const SliderButtons = styled.div`
  position: absolute;
  bottom: 100px;
  right: 50px;
  display: flex;
  z-index: 10;
`;

const arrowButtons = styled.button`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #000d1a;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #cd8538;
    transform: scale(1.05);
  }
`;

export const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
`;

export const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;

export const HeroButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const HeroBg = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #0f1723;
  display: flex;
  flex-direction: row;
`;

export const VideoBg = styled.video`
  width: 40%;
  height: 90%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #000;
`;
export const OverLay = styled.div`
  background: red; //Change as per your requirement
  width: 100%;
  height: 100%;
  opacity: 0.1;
  position: relative;
  z-index: 999;
`;

export const InfoContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 60%;
  padding: 10px 20px;
`;

export const MediaContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30%;
  width: 100%;
`;
export const HeroImg = styled.img`
  height: 100%;
  width: 100%;
`;

export const FeatureContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FeatureLabel = styled.span`
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  margin: 10px;
`;
export const HeroBtnContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;
export const BtnWrapper = styled.div`
  margin: 20px;
`;

export const HeroBtn = styled.button`
  height: 50px;
  width: 200px;
  background: #fff;
  color: #000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  border: 0px solid transparent;
`;
export const MarketBtn = styled.button`
  height: 50px;
  width: 200px;
  background: rgb(255, 120, 31);
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  border: 0px solid transparent;
`;

export const TextWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
