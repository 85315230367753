import PropertyCard from "../../PropertyCard"
export const homeObjOne = {
  id: "why_us",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Invest, Earn and Grow your capital with a click of a button",
  description:
    "We aim to be one of the world's highest ranked technologically advanced commercial real estate investment platform to help investors get started on their investment journey",
  buttonLabel: "Get Started",
  pointOne: "This is Point 1",
  pointTwo: "This is Point 2",
  pointThree: "This is Point 3",
  imgStart: true,
  img: require("../../../../assets/why.jpeg"),
  alt: "bank",
  dark: true,
  primary: true,
  darkText: false,
  desc: true,
  points: false,
  showButton:true
};

export const homeObjTwo = {
  id: "cre",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Premium Investment",
  headline: "Why Commercial Real Estate",
  description: "...",
  pointOne:
    "Stay insulated from market fluctuations by investing in a stable asset class",
  pointTwo:
    "Historically, commercial real estate has delivered market-beating performance with improved cash flows",
  pointThree:
    "Mitigate portfolio risk by diversifying investments across multiple properties",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../../../../assets/group3.svg"),
  alt: "bank",
  dark: true,
  primary: true,
  darkText: true,
  desc: false,
  points: true,
  showButton:false
};

export const homeObjThree = {
  id: "how",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Easy Learning",
  headline: "How it works",
  description: "Bla blabla ajhasdkjahkjahdkajsd asdaksdkajsd kjasdkjhasdasdj",
  pointOne:
    "You can fractionally invest into or sell property on our blockchain marketplace",
  pointTwo:
    "Let MIA help you make profitable & safe investment & selling options",
  pointThree: "Track the performance of your investments & properties",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../../../assets/group2.svg"),
  alt: "bank",
  dark: true,
  primary: true,
  darkText: true,
  desc: false,
  points: true,
  showButton:false
};

export const homeObjFour = {
  id: "fire",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Easy Learning",
  headline: "FIRE",
  description: "Bla blabla ajhasdkjahkjahdkajsd asdaksdkajsd kjasdkjhasdasdj",
  pointOne:
    "You can fractionally invest into or sell property on our blockchain marketplace",
  pointTwo:
    "Let MIA help you make profitable & safe investment & selling options",
  pointThree: "Track the performance of your investments & properties",
  buttonLabel: "Get Started",
  imgStart: false,
  img: null,
  alt: "bank",
  dark: true,
  primary: true,
  darkText: true,
  desc: false,
  points: true,
  showButton:false,
  showComponent:true,
  
};

