import React from "react";
import AnimatedText from "../../AnimatedText";
import Slide from "react-reveal/Slide";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TopLine,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
  SubLine,
} from "./Styles";
import StyledBtn from "../../StyledBtn";

interface CsProps {
  component?: any;
  id?: string;
  imgStart?: boolean;
  points?: boolean;
  desc?: boolean;
  showButton?: boolean;
  img?: string;
  buttonLabel?: string;
  pointOne?: string;
  pointTwo?: string;
  pointThree?: string;
  description?: string;
  darkText?: boolean;
  lightBg?: boolean;
  lightText?: boolean;
  topLine?: string;
  headline?: string;
  showImg?: boolean;
  subLevel?: string;
}

const CustomSection = (props: CsProps) => {
  const {
    component,
    id,
    imgStart,
    points,
    desc,
    showButton,
    img,
    showImg,
    buttonLabel,
    pointOne,
    pointTwo,
    pointThree,
    description,
    darkText,
    lightText,
    lightBg,
    topLine,
    headline,
    subLevel,
  } = props;
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Slide top>
                  <Heading lightText={lightText}>{headline}</Heading>
                </Slide>
                <Slide bottom>
                  <SubLine>{subLevel}</SubLine>
                </Slide>

                {points && !desc ? (
                  <>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointOne}</Subtitle>
                    </Slide>

                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointTwo}</Subtitle>
                    </Slide>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointThree}</Subtitle>
                    </Slide>
                  </>
                ) : (
                  <>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{description}</Subtitle>
                    </Slide>
                  </>
                )}
              </TextWrapper>
              <BtnWrap>
                {showButton ? (
                  <StyledBtn
                    title={buttonLabel}
                    fontSize={15}
                    titleBasecolor="#fff"
                    titleHovercolor="#101824"
                    borderColor="#ff781f"
                    bgHovercolor="#fff"
                    bgBasecolor="#ff781f"
                    onPress={() =>
                      (location.href = "https://investor.realtoprop.com/")
                    }
                  />
                ) : null}
              </BtnWrap>
            </Column1>
            <Column2>
              <ImgWrap>{component}</ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default CustomSection;
