import React from "react";
import AnimatedText from "../../AnimatedText";
import Slide from "react-reveal/Slide";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TopLine,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
} from "./Styles";
import StyledBtn from "../../StyledBtn";

interface ZeroProps {
  component?: any;
}

const SectionZero = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  darkText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  pointOne,
  pointTwo,
  pointThree,
  desc,
  points,
  showComponent,
  component,
  showButton,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Slide top>
                  <Heading lightText={lightText}>{headline}</Heading>
                </Slide>

                {points && !desc ? (
                  <>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointOne}</Subtitle>
                    </Slide>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointTwo}</Subtitle>
                    </Slide>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{pointThree}</Subtitle>
                    </Slide>
                  </>
                ) : (
                  <>
                    <Slide bottom>
                      <Subtitle darkText={darkText}>{description}</Subtitle>
                    </Slide>
                  </>
                )}
              </TextWrapper>
              <BtnWrap>
                {showButton ? (
                  <StyledBtn
                    title={buttonLabel}
                    fontSize={15}
                    titleBasecolor="#fff"
                    titleHovercolor="#101824"
                    borderColor="#ff781f"
                    bgHovercolor="#fff"
                    bgBasecolor="#ff781f"
                    onPress={() =>
                      (location.href = "https://investor.realtoprop.com/")
                    }
                  />
                ) : null}
              </BtnWrap>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default SectionZero;
