
import styled from "styled-components";

export const CardContainer = styled.div`
  width: 40vw;
  height: 90%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);
  box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);
  top: 50px;

  @media only screen and (max-width: 768px) {
    width: 90vw;
    top: 0;
  }
`;

export const ImgWrap = styled.div`
  max-width: 655px;
  height: 30%;
  position: relative;

`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const InfoWrap = styled.div`
display: flex;
flex-direction: column;
width: 100%;


`;
export const StatsContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items:center;
justify-content:space-between;
margin-bottom:2px;
`;
export const ProgressContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 30px;

border-bottom: 2px solid #233037;
`;

export const Progress= styled.div`
width: 100%;
background: #233037;
text-align: center;
padding: 5px;
font-family: "Roboto Condensed", sans-serif;
color:#58FBA3;
font-weight: bold;
`

export const StatsTitle = styled.span`
font-size: 14px;
font-family: "Roboto Condensed", sans-serif;
text-align: center;



`;
export const Stat = styled.span`
font-size: 23px;
font-family: "Roboto Condensed", sans-serif;
font-weight: bold;
text-align: center;
color: #0f1723;


`;

export const StatYield = styled.span`
font-size: 23px;
font-weight: bold;
text-align: center;
color:#0f1723;
font-family: "Roboto Condensed", sans-serif;
`;

export const TextWrapper=styled.div`
display: flex;
flex-direction:column;
margin: 10px;
`
export const RowTwo = styled.div`
display: flex;
flex-direction: column;
align-items:center;
justify-content: space-between;
border-right: 1px solid #e8e8e8;

`;
export const RowOne = styled.div`
display: flex;
flex-direction: column;
align-items:center;
justify-content: space-between;
border-right: 1px solid #e8e8e8;
`;