import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";

import Lottie from "lottie-web";
import styled from "styled-components";

const Welcome = ({ navigation }) => {
  const container = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: "svg",

      loop: true,
      autoplay: true,
      animationData: require("../../../assets/loader.json"),
    });
  }, []);



  return (
    <View style={styles.splash}>
      <View style={styles.holder}>
        <div
          style={{ height: 150, width: 150 }}
          className="container"
          ref={container}
        ></div>
        <View style={{ marginLeft: 20 }}>
          <Text style={{ fontSize: 24, color: "#adadad" }}>Welcome to</Text>
          <Text style={{ fontSize: 70, color: "#fff", fontWeight: "600" }}>
            realto
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  splash: {
    flex: 1,
    backgroundColor: "rgb(15, 23, 35)",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  holder: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "90%",
  },
});

export default Welcome;
