import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  ImageBackground,
} from "react-native";
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";
import {
  Container,
  FooterWrap,
  LetterWrapper,
  LetterSub,
  LetterHead,
  Input,
  SubmitWrapper,
  FooterContainer,
  ImgLogo,
  SocialImgWrap,
  FooterLinksItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterLink,
  Social,
  SocialWrap,
  SocialLogo,
  SocialIconLink,
  SocialIcons,
  Address,
} from "./Elements";
import image from "../../../assets/image_7.jpg";
import StyledBtn from "../StyledBtn";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import dark from "../../../assets/darklogo.png";

export interface FooterProps {
  bgColor?: string;
  id?: string;
}

const Footer = (props: FooterProps) => {
  const { bgColor, id } = props;

  return (
    <Container
      style={{ backgroundColor: bgColor ? bgColor : "#fff" }}
      id={id ? id : ""}
    >
      <LetterWrapper>
        <ImageBackground source={image} style={styles.ImageBG}>
          <LetterHead>Get access to latest updates</LetterHead>
          <LetterSub>Join us today by entering your email</LetterSub>
          <SubmitWrapper>
            <Input placeholder="Your Email" />
            <StyledBtn
              title="Submit"
              fontSize={18}
              titleBasecolor="#fff"
              titleHovercolor="#0f1723"
              borderColor="#ff781f"
              bgHovercolor="#fff"
              bgBasecolor="#ff781f"
              onPress={() => console.log("Hello")}
            />
          </SubmitWrapper>
        </ImageBackground>
      </LetterWrapper>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinksItems>
                <FooterLinkTitle>Overview </FooterLinkTitle>
                <FooterLink href="#home">Home</FooterLink>
                <FooterLink href="#offerings">Offerings</FooterLink>
                <FooterLink href="#how">How it works</FooterLink>
                <FooterLink href="#why__us">Why us</FooterLink>
              </FooterLinksItems>
              <FooterLinksItems>
                <FooterLinkTitle>About </FooterLinkTitle>
                <FooterLink href="#hero">Contact</FooterLink>
                <FooterLink href="#offerings">Support</FooterLink>
                <FooterLink href="#hero">Terms and Policies</FooterLink>
                <FooterLink href="#hero">Disclaimer</FooterLink>
              </FooterLinksItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
              <FooterLinksItems>
                <FooterLinkTitle>Knowledge </FooterLinkTitle>
                <FooterLink href="#hero">FAQs</FooterLink>
                <FooterLink href="#offerings">Login</FooterLink>
                <FooterLink href="#hero">Sign up</FooterLink>
              </FooterLinksItems>
              <FooterLinksItems>
                <FooterLinkTitle>Social </FooterLinkTitle>
                <FooterLink href="#hero">Facebook</FooterLink>
                <FooterLink href="#offerings">Twitter</FooterLink>
                <FooterLink href="#hero">YouTube</FooterLink>
                <FooterLink href="#hero">LinkedIn</FooterLink>
              </FooterLinksItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <Social>
            <SocialWrap>
              <SocialImgWrap>
                <ImgLogo src={dark} />
                <SocialLogo href="#home">realto</SocialLogo>
              </SocialImgWrap>

              <Address>
                5899 Preston Road, Suite # 403, Frisco, TX 75034, USA.
              </Address>
              <SocialIcons>
                <SocialIconLink href="#" target="_blank" aria-label="Facebook">
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href="#" target="_blank" aria-label="Youtube">
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink href="#" target="_blank" aria-label="Instagram">
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href="#" target="_blank" aria-label="Twitter">
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink href="#" target="_blank" aria-label="LinkedIn">
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </SocialWrap>
          </Social>
        </FooterWrap>
      </FooterContainer>
    </Container>
  );
};

const styles = StyleSheet.create({
  ImageBG: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default Footer;
