import React from "react";
import styled from "styled-components";

export interface StyledBtnProps {
  title: string;
  onPress(): any;
  bgBasecolor?: string;
  bgHovercolor?: string;
  titleBasecolor?: string;
  titleHovercolor?: string;
  borderColor?: string;
  fontSize?: number;
}

const StyledBtn = (props: StyledBtnProps) => {
  const {
    title,
    onPress,
    bgBasecolor,
    bgHovercolor,
    titleBasecolor,
    titleHovercolor,
    borderColor,
    fontSize,
  } = props;

  const Wrapper = styled.button`
    color: ${titleBasecolor};
    border: 2px solid ${borderColor};
    border-radius: 0px;
    background: ${bgBasecolor};
    padding: 18px 36px;
    display: inline-block;
    font-size: ${fontSize}px;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 ${bgHovercolor};
    -webkit-transition: ease-out 0.2s;
    -moz-transition: ease-out 0.2s;
    transition: ease-out 0.2s;

    &:hover {
      box-shadow: inset 400px 0 0 0 ${bgHovercolor};
      color: ${titleHovercolor};
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  `;

  return <Wrapper onClick={onPress}>{title}</Wrapper>;
};

export default StyledBtn;
