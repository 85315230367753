import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef, ReactChild } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";
import { Container } from "./Styles";

export interface HeroProps {
  bgColor?: string;
  id?: string;
  component: any;
}

const Hero = (props: HeroProps) => {
  const { bgColor, id, component } = props;

  return (
    <Container
      style={{ backgroundColor: bgColor ? bgColor : "#fff" }}
      id={id ? id : ""}
    >
      {component}
    </Container>
  );
};

export default Hero;
