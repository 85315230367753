import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import Button from "./src/components/Button";
import Lottie from "lottie-web";
import styled from "styled-components";
import Welcome from "./src/components/Welcome/Welcome";
import Home from "./src/pages/Home";
import * as ScreenOrientation from "expo-screen-orientation";
import {ThemeProvider,createGlobalStyle} from 'styled-components'

const App = () => {
  const [loading, setloading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, [loading]);

  return (
    <ThemeProvider theme={{mode:"dark"}}>
 <View style={styles.container}>
      {loading ? <Welcome /> : <Home />}

      <StatusBar style="auto" />
    </View>
    </ThemeProvider>
   
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  splash: {
    flex: 1,
    backgroundColor: "#0f1723",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  holder: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "90%",
  },
});

export default App;
