import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  Dimensions,
} from "react-native";
import Button from "../../components/Button";
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";
import HeroSection from "../../components/Sections/HeroSection";
import NavBar from "../../components/StyledNav";
import SectionOne from "../../components/Sections/SectionOne";
import SectionTwo from "../../components/Sections/SectionTwo";
import SectionThree from "../../components/Sections/SectionThree";
import SectionFire from "../../components/Sections/SectionFire";
import { SliderData } from "../../data/SliderData";
import SectionZero from "../../components/Sections/SectionZero";
import SideBar from "../../components/Sidebar";
import {
  homeObjFour,
  homeObjOne,
  homeObjThree,
  homeObjTwo,
} from "../../components/Sections/SectionZero/Data";
import Tiles from "../../components/Tiles";
import styled from "styled-components";
import ScrollSnap from "scroll-snap";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import mia from "../../../assets/mia.jpeg";
import StyledBtn from "../../components/StyledBtn";
import CustomSection from "../../components/Sections/CustomSection";
import PropertyCard from "../../components/PropertyCard";
import YTCard from "../../components/Youtube";
const Home = () => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!isOpen);
    setactiveSnap(false);
    var scrollPos = 0;
  };

  const [opened, setopened] = useState(false);
  const [activeSnap, setactiveSnap] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // setopened(true);
    }, 2000);
  }, []);

  const toggleFloating = () => {
    setopened(!opened);
  };

  const windowWidth = Dimensions.get("screen").width;
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    windowWidth < 768 ? setisMobile(true) : setisMobile(false);
  }, [windowWidth]);

  const Container = styled.div`
    height: 100vh;
    width: 100%;
    position: relative;
    scroll-behavior: ${(props) => {
      if (isMobile) {
        return `none`;
      } else {
        return `smooth`;
      }
    }};
    overflow: auto;
    scroll-snap-type: ${(props) => {
      if (activeSnap && !isMobile) {
        return `y mandatory`;
      } else {
        return `none`;
      }
    }};
    scroll-behavior: smooth;
  `;

  const theme = {
    background: "#fff",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#ff781f",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#ff781f",
    botFontColor: "#fff",
    userBubbleColor: "#101824",
    userFontColor: "#fff",
  };

  const [fromgs, setfromgs] = useState(false);

  const handleScroll = () => {
    console.log("Scrolling");
    setactiveSnap(true);
  };
  // const [orientation, setOrientation] = useState("PORTRAIT");

  // useEffect(() => {
  //   Dimensions.addEventListener("change", ({ window: { width, height } }) => {
  //     if (width < height) {
  //       setOrientation("PORTRAIT");
  //     } else {
  //       setOrientation("LANDSCAPE");
  //     }
  //   });
  // }, []);

  // const Wrapper = styled.div`
  //   transform: ${(props) => {
  //     if (orientation === "LANDSCAPE") {
  //       return `rotate(90deg)`;
  //     } else {
  //       return `rotate(0deg)`;
  //     }
  //   }};
  // `;

  const [dark, setdark] = useState(true);

  const themeToggler = () => {
    setdark(!dark);
  };

  return (
    <div>
      <View style={styles.container}>
        <SideBar isOpen={isOpen} toggle={toggle} switchtheme={themeToggler} />
        <NavBar toggle={toggle} />

        <Container mobile={isMobile}>
          <Hero
            bgColor="#0f1723"
            id="home"
            component={
              <HeroSection
                slides={SliderData}
                onGetStarted={() => {
                  setopened(true), setfromgs(true);
                }}
              />
            }
          />

          <Hero
            bgColor="blue"
            id="why_us"
            component={
              <CustomSection
                id="why_us"
                points={false}
                lightText={dark}
                topLine=""
                imgStart={false}
                headline="Invest, Earn and Grow your capital with a click of a button"
                lightBg={!dark}
                darkText={!dark}
                desc={true}
                description="We aim to be one of the world's highest ranked technologically advanced commercial real estate investment platform to help investors get started on their investment journey"
                component={<YTCard />}
              />
            }
          />
          <Hero
            bgColor="red"
            id="offerings"
            component={
              <CustomSection
                id="offerings"
                points={false}
                lightText={!dark}
                topLine="View sample offering"
                subLevel="Hadapsar, Pune"
                showButton={true}
                buttonLabel="View Opportunity"
                headline="DLF Cybercity"
                lightBg={dark}
                darkText={dark}
                desc={true}
                description="Among the most successful integrated townships in India. Cybercity has consistently maintained one of the lowest vacancy rates and boasts a stellar tenancy profile."
                component={<PropertyCard />}
              />
            }
          />
          <Hero bgColor="#101824" id="services" component={<Tiles />} />
          <Hero
            bgColor="green"
            id="cre"
            component={<SectionZero {...homeObjTwo} />}
          />

          <Hero
            bgColor="green"
            id="how"
            component={<SectionZero {...homeObjThree} />}
          />
          <Hero
            bgColor="green"
            id="contact"
            component={<Footer bgColor="#0f1723" />}
          />

          {/* component?: any; id?: string; imgStart?:boolean; points?:boolean;
          desc?:boolean; showButton?:boolean; img?:string; buttonLabel?:string;
          pointOne?:string; pointTwo?:string; pointThree?:string; description?:
          string; */}
          {/* <Hero bgColor="red" id="fire" component={<SectionFire />} /> */}
          {/* <Footer /> */}
        </Container>
        <StatusBar style="auto" />
      </View>
      <ThemeProvider theme={theme}>
        <div
          style={{
            bottom: 10,
            right: 10,
            position: "sticky",
            float: "right",
          }}
        >
          <ChatBot
            floating={true}
            style={{
              borderRadius: 0,
              backgroundColor: "#fff",
              borderWidth: 1,
              borderColor: "#000",
            }}
            bubbleStyle={{ borderRadius: 0 }}
            bubbleOptionStyle={{ borderRadius: 0 }}
            inputStyle={{ borderRadius: 0 }}
            opened={opened}
            toggleFloating={toggleFloating}
            floatingIcon={mia}
            headerTitle="Hello, I'm Mia"
            botAvatar={mia}
            steps={[
              {
                id: "1",
                message:
                  "Hey there, I am MIA, your real estate investment & sales advisor. I am here to help you understand what Realto can do for you with our Ïnvest Grow & Reap Strategy",
                trigger: "2",
              },
              {
                id: "2",
                message: "What is your name?",
                trigger: "3",
              },
              {
                id: "3",
                user: true,
                trigger: "4",
              },

              {
                id: "4",
                message:
                  "Hi {previousValue}, nice to meet you! May I know your email ?",
                trigger: "5",
              },
              {
                id: "5",
                user: true,
                trigger: "6",
              },
              {
                id: "6",
                message: "What is guiding your investment journey ?",
                trigger: "7",
              },
              {
                id: "7",
                options: [
                  {
                    value: 1,
                    label: "I want to invest in real estate",
                    trigger: "8",
                  },
                  {
                    value: 2,
                    label: "I want portfolio diversification",
                    trigger: "8",
                  },
                  {
                    value: 3,
                    label: "I am currently saving for retirement",
                    trigger: "8",
                  },
                  { value: 4, label: "Not sure right now", trigger: "8" },
                ],
              },
              {
                id: "8",
                component: (
                  <StyledBtn
                    title="Alright then take me to SignUp"
                    fontSize={14}
                    titleBasecolor="#ff781f"
                    titleHovercolor="#ff781f"
                    borderColor="#101824"
                    bgHovercolor="#fff"
                    bgBasecolor="#101824"
                    onPress={() =>
                      (location.href =
                        "https://master.d12goja7jfg31e.amplifyapp.com/signup")
                    }
                  />
                ),
              },
              {
                id: "9",
                message: "Thanks! Your data was submitted successfully!",
                end: true,
              },
            ]}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
  },
});

export default Home;
