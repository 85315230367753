import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";

export const SideBarContainer = styled.aside`
  position: fixed;
  z-index: 100001;
  width: 100%;
  height: 100%;
  background: #0f1723;
  display: grid;
  align-items: center;
  /* top: 0; */
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0%" : "-100%")};
  transition: 0.3s ease-in-out;
`;


export const NavLinks = styled.a`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
font-family: 'Roboto Condensed', sans-serif;
padding: 0 1rem;
height: 100%;
cursor: pointer;

&.active{
    border-bottom: 3px solid #ff781f;
    color:#ff781f;

}

&:hover{
margin-bottom: 10px;
font-size: 24px;
transition: font 0.3s ease

}


@media only screen and (min-width:768px){
  font-size:20px;
}
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SideBarWrapper = styled.div`
  color: #fff;
`;

export const SideBarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;
export const SideBarLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ff781f;
    transition: 0.2s ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const SideBtnLink = styled.a`
  background: #fff;
  white-space: nowrap;
  padding: 16px 64px;
  color: #0f1723;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  margin: 10px 20px;

  &.hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #0f1723;
  }

  @media only screen and (min-width: 768px){
    width: 110px;
    height: 20px;
    font-size: 16px;
    text-align: center;
    align-self: center;
    

  }
`;



export const SideBtnLinkTwo = styled.a`
  background: #ff781f;
  white-space: nowrap;
  padding: 16px 64px;
  color: #0f1723;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  margin: 10px 20px;

  &.hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ff781f;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;


