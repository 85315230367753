import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: black;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const LetterWrapper = styled.div`
  width: 100%;
  height: 20%;
`;

export const LetterHead = styled.span`
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  margin: 10px;
`;

export const LetterSub = styled.span`
  color: #fff;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  margin: 20px;
`;
export const Input = styled.input`
  width: 30%;
  height: 55px;
  font-size: 18px;
  border: 1px solid #fff;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  margin-right: 5px;
  -webkit-box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);
  box-shadow: -2px 0px 19px 0px rgba(0, 0, 0, 0.31);

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
export const SubmitWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: "row";
  height: 50px;
  align-items: center;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 80%;
  
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinksItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
  font-family: "Roboto Condensed", sans-serif;
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;

  &:hover {
    color: #ff781f;
    transition: 0.3s ease-in-out;
  }
`;

export const Social = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled.a`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
`;

export const Address = styled.small`
  color: #fff;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const SocialImgWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ImgLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
`;
