import React from "react";
import { Dimensions } from "react-native";

const YTCard = () => {
  const WINDOW_WIDTH = Dimensions.get("screen").width;
  return (
    <>
      <iframe
        width={WINDOW_WIDTH > 768 ? WINDOW_WIDTH / 3 : WINDOW_WIDTH / 1.2}
        height="315"
        src="https://www.youtube.com/embed/ZltHS6bspDY?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </>
  );
};

export default YTCard;
