import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  LogoContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavBtnLinkTwo,
  SocialLogo,
  LogoSub,
} from "./Elements";

import { FaBars } from "react-icons/fa";

interface NavBarProps {
  toggle(): any;
}
const NavBar = (props: NavBarProps) => {
  const { toggle } = props;
  return (
    <>
      <Nav>
        <NavbarContainer>
          <LogoContainer>
            <NavLogo src={require("../../../assets/Realto.png")}></NavLogo>
            <div>
              <SocialLogo href="#home">realto</SocialLogo>
              <LogoSub>Invest. Grow. Reap. </LogoSub>
            </div>
          </LogoContainer>
          <NavBtn>
            <NavBtnLink href="https://investor.realtoprop.com/signup">
              Invest Now
            </NavBtnLink>
          </NavBtn>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                href="#home"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                href="#why_us"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                Our Promise
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                href="#offerings"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                Offerings
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                href="#services"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                Services
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                href="#how"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                How it works
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                href="#fire"
                smooth={true}
                activeClass="active"
                spy={true}
                offset={-70}
                duration={300}
              >
                F.I.R.E
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavBar;
