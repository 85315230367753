import ImageOne from "../../assets/slider3.jpeg";
import ImageTwo from "../../assets/slider2.jpeg";
import ImageThree from "../../assets/slider1.jpeg";
import ImageFour from "../../assets/Image_1.jpg";

export const SliderData = [
  {
    title: "Commercial Real Estate Reclassified",
    subtitle: "Giving unparalled access to institutional quality real estate deals online",
    label: "View",
    image: ImageOne,
    alt: "Slide",
  },
  {
    title: " Get 8X returns on investments",
    subtitle:
      "Invest in a historically proven stable and secure asset and get higher returns",
    label: "View",
    image: ImageTwo,
    alt: "Slide",
  },
  {
    title: "Monitor and sell investments in real time",
    subtitle: "Using state-of-the-art portal, get data insights and analysis",
    label: "View",
    image: ImageThree,
    alt: "Slide",
  },
  {
    title: "Grow and reap with Property Advisor  - MIA",
    subtitle: "Get personalized suggestions and recommendations from MIA",
    label: "View",
    image: ImageFour,
    alt: "Slide",
  },
];
