import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  HeroSectionContainer,
  HeroWrapper,
  HeroSlide,
  HeroSlider,
  HeroImage,
  HeroContent,
  SliderButtons,
  HeroBtnContainer,
  TextWrapper,
  BtnWrapper,
  HeroSub,
} from "./Styles";

import AnimatedText from "../../AnimatedText";
import StyledBtn from "../../StyledBtn";
import { Dimensions } from "react-native";

interface HeroProps {
  slides: [];
  onGetStarted(): any;
}

const HeroSection = (props: HeroProps) => {
  const { slides, onGetStarted } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [current, setcurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);
  const WINDOW_WIDTH = Dimensions.get("window").width;

  const nextSlide = () => {
    setcurrent(current === length - 1 ? 0 : current + 1);
    console.log(current);
  };

  const previousSlide = () => {
    setcurrent(current === 0 ? length - 1 : current - 1);
    console.log(current);
  };

  useEffect(() => {
    const nextSlide = () => {
      setcurrent((current) => (current === length - 1 ? 0 : current + 1));
    };

    timeout.current = setTimeout(nextSlide, 5000);

    return function() {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <HeroSectionContainer>
      <HeroWrapper>
        {slides.map((slide, index) => {
          return (
            <HeroSlide key={index}>
              {index === current && (
                <HeroSlider>
                  <HeroImage src={slide.image} alt={slide.alt} />
                  <HeroContent>
                    <AnimatedText
                      fontSize={34}
                      label={slide.title}
                      bgColor="#ff781f"
                      color="#fff"
                      margin={5}
                    />

                    <HeroSub>{slide.subtitle}</HeroSub>

                    <HeroBtnContainer>
                      <BtnWrapper>
                        <StyledBtn
                          title="Get Started"
                          fontSize={18}
                          titleBasecolor="#101824"
                          titleHovercolor="#fff"
                          borderColor="#fff"
                          bgHovercolor="#101824"
                          bgBasecolor="#fff"
                          onPress={onGetStarted}
                        />
                      </BtnWrapper>

                      <BtnWrapper>
                        <StyledBtn
                          title="Visit Marketplace"
                          fontSize={18}
                          titleBasecolor="#ff781f"
                          titleHovercolor="#fff"
                          borderColor="#ff781f"
                          bgHovercolor="#ff781f"
                          bgBasecolor="#0f1723"
                          onPress={() =>
                            (location.href =
                              "https://investor.realtoprop.com/signin")
                          }
                        />
                      </BtnWrapper>
                    </HeroBtnContainer>
                  </HeroContent>
                </HeroSlider>
              )}
            </HeroSlide>
          );
        })}
        {WINDOW_WIDTH > 768 ? (
          <SliderButtons>
            <StyledBtn
              title="<"
              fontSize={20}
              titleBasecolor="#ff781f"
              titleHovercolor="#fff"
              borderColor="#0f1723"
              bgHovercolor="#ff781f"
              bgBasecolor="#0f1723"
              onPress={previousSlide}
            />
            <StyledBtn
              title=">"
              fontSize={20}
              titleBasecolor="#ff781f"
              titleHovercolor="#0f1723"
              borderColor="#0f1723"
              bgHovercolor="#fff"
              bgBasecolor="#0f1723"
              onPress={nextSlide}
            />
          </SliderButtons>
        ) : null}
      </HeroWrapper>
    </HeroSectionContainer>
  );
};

export default HeroSection;
