import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./Styles";

import Icon1 from "../../../assets/f4.svg";
import Icon2 from "../../../assets/f2.svg";
import Icon3 from "../../../assets/f3.svg";

import { ImageBackground, StyleSheet } from "react-native";
import image from "../../../assets/image_7.jpg";
const Tiles = () => {
  return (
    <ServicesContainer id="services">
      {/* <ImageBackground source={image} style={styles.ImageBG}> */}
      <ServicesH1>The Realto Advantage</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2> Strong Foundation</ServicesH2>
          <ServicesP>
            Founded by industry veterans and with extensive global & local
            expertise
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>The Tech Edge </ServicesH2>
          <ServicesP>
            Cutting edge platform and protocols based on Blockchain and
            Artificial intelligence
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2> Premium Benefits</ServicesH2>
          <ServicesP>
            Investors Risk Profile analysis, Personalized preferences and
            updates with AI
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
      {/* </ImageBackground> */}
    </ServicesContainer>
  );
};

export default Tiles;
const styles = StyleSheet.create({
  nonBlurredContent: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  ImageBG: {
    flex: 1,
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
});
