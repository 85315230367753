import React from "react";
import { Wrapper } from "./Elements";
import styled from "styled-components";

export interface TextProps {
  label: string;
  fontSize: number;
  bgColor: string;
  color?: string;
  margin?: number;
}
const AnimatedText = (props: TextProps) => {
  const { label, fontSize, bgColor, color, margin } = props;

  const Wrapper = styled.span`
    &::after {
      animation-delay: var(--animation-delay, 2s);
      animation-iteration-count: var(--iterations, 1);
      animation-duration: var(--duration, 800ms);
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    /* font-size: 60px;
color: #fff;
font-weight: bold;
font-family: 'Roboto Condensed', sans-serif;
margin-bottom: 10; */
    --animation-delay: var(--delay, 0);
    --animation-duration: var(--duration, 800ms);
    --animation-iterations: var(--iterations, 1);
    position: relative;
    text-align: left;
    font-size: 60px;
    animation-name: clip-text;
    color: ${color};
    font-family: "Roboto Condensed", sans-serif;

    margin: ${margin}px;
    cursor: default;

    &::after {
      content: "";
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${bgColor};
      transform: scaleX(0);
      transform-origin: 0 50%;
      pointer-events: none;
      animation-name: text-revealer;
    }

    @keyframes clip-text {
      from {
        clip-path: inset(0 100% 0 0);
      }
      to {
        clip-path: inset(0 0 0 0);
      }
    }

    @keyframes text-revealer {
      0%,
      50% {
        transform-origin: 0 50%;
      }

      60%,
      100% {
        transform-origin: 100% 50%;
      }

      60% {
        transform: scaleX(1);
      }

      100% {
        transform: scaleX(0);
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }
  `;
  return <Wrapper style={{ fontSize: fontSize }}>{label}</Wrapper>;
};

export default AnimatedText;
