import React from "react";
import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarWrapper,
  SideBarMenu,
  NavLinks,
  SideBtnWrap,
  SideBtnLink,
  SideBtnLinkTwo,
  NavBtnLink,
} from "./Styles";

interface SideBarProps {
  isOpen: boolean;
  toggle(): any;
}
const SideBar = ({ isOpen, toggle, switchtheme }) => {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarWrapper>
        <SideBarMenu>
          <NavLinks href="#home" onClick={toggle}>
            Home
          </NavLinks>
          <NavLinks href="#why_us" onClick={toggle}>
            Our Promise
          </NavLinks>
          <NavLinks href="#offerings" onClick={toggle}>
            Offerings
          </NavLinks>
          {/* <NavLinks href="#cre" onClick={toggle}>
           Commercial Real Estate
          </NavLinks> */}
          <NavLinks href="#services" onClick={toggle}>
            Services
          </NavLinks>
          <NavLinks href="#how" onClick={toggle}>
            How it works
          </NavLinks>
          <NavLinks href="#contact" onClick={toggle}>
            Contact Us
          </NavLinks>
          {/* <NavLinks
            onClick={() => {
              switchtheme();
              toggle();
            }}
          >
            Switch Theme
          </NavLinks> */}
        </SideBarMenu>
        <SideBtnWrap>
          <SideBtnLink
            href="https://investor.realtoprop.com/signin"
            target="_blank"
          >
            Login
          </SideBtnLink>
          <SideBtnLinkTwo
            href="https://investor.realtoprop.com/signup"
            target="_blank"
          >
            Invest Now
          </SideBtnLinkTwo>
        </SideBtnWrap>
      </SideBarWrapper>
    </SideBarContainer>
  );
};

export default SideBar;
